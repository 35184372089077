const BASE_URL = window.location.origin;
// const APP_URL = "https://crm.closecall.in/inhouse-call/public/api/";
// const  APP_URL = "https://demo.closecall.in/inhouse-call/public/api/";
const APP_URL = BASE_URL+'/inhouse-call/public/api/';
const getdates = (day = 0) => {
  // Get today's date
  let today = new Date();
  // Subtract the number of days from the current date
  today.setDate(today.getDate() - day);
  // Format the date to YYYY-MM-DD format
  let getdate = today.getDate();
  let getmonth = today.getMonth() + 1; // getMonth() is 0-indexed, so add 1
  let getyear = today.getFullYear();
  // Ensure two-digit formatting for day and month
  let update_date = getdate < 10 ? "0" + getdate : getdate;
  let update_month = getmonth < 10 ? "0" + getmonth : getmonth;
  // Concatenate the date components
  let currdate = `${getyear}-${update_month}-${update_date}`;
  return currdate;
};
export { APP_URL, getdates, BASE_URL };

