// import logo from './logo.svg';
import { HashRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from './component/pages/dashboard';
import OutBoundDashboard from './component/pages/Outbound';
import Company from './component/pages/company';
import Department from './component/pages/department';
import Employee from './component/pages/employee';
import AddEmployee from './component/pages/employee/addemployee';
import User from './component/pages/users/users';
import AddUser from './component/pages/users/adduser';
import Edituser from './component/pages/users/edituser';
import Excludenumber from './component/pages/manage/Exclude';
import Callrecording from './component/pages/manage/Callrecording';
import Periodicreport from './component/pages/reports/Periodicreport';
import Neverattended from './component/pages/reports/Neverattended';
import Notpickup from './component/pages/reports/Notpickup';
import Employeereport from './component/pages/reports/Employeereport';
import Clientreport from './component/pages/reports/Clientreport';
import Login from './component/pages/login';
import LiveMonitoring from './component/pages/monitoring/live_monitoring';
import ConnectDevice  from './component/pages/manage/connect_device';
import AppUsage from './component/pages/reports/Appusage';
import Role from './component/pages/manage/role_and_permission/role';
import AddRole from './component/pages/manage/role_and_permission/addrole';
import Permission from './component/pages/manage/role_and_permission/permission';
import Menu from './component/pages/manage/role_and_permission/menu';
import AddMenu from './component/pages/manage/role_and_permission/addmenu';
import AddPermission from './component/pages/manage/role_and_permission/addpermission';
import EditRole from './component/pages/manage/role_and_permission/editrole';
import Backup from './component/pages/setting/Backup';
import LocationReport from './component/pages/reports/LocationReport';
import Highestcall from './component/pages/reports/Highestcall';
import Pricing from './component/pages/setting/PricingPlan';
import AddPlan from './component/pages/setting/AddPlan';
import Break from './component/pages/setting/Break';
import ManageApis from './component/pages/setting/ManageApi';
import Topbar from './component/layout/topbar';
import LeadInbox from './component/pages/lead/LeadInbox';
import LeadFuture from './component/pages/lead/LeadFuture';
import LeadNegative from './component/pages/lead/LeadNegative';
import LeadCompleted from './component/pages/lead/LeadCompleted';
import LeadTags from './component/pages/lead/LeadTags';
import LeadPower from './component/pages/lead/LeadPower';
import FollowUp from './component/pages/lead/FollowUpBox';
import MailTemplate from './component/pages/lead/Template';
import AddTemplate from './component/pages/lead/AddTemplate';
import BreakReport from './component/pages/reports/BreakReport';
import ApiResource from './component/pages/setting/ApiResource';
import { useEffect } from "react";
function App() {
  
  const isloggedin = localStorage.getItem('isloggedin');  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem('isloggedin') == null) {
      navigate("/");
    }
    if(location.pathname == "/"){
      if(localStorage.getItem('isloggedin') == null){
        navigate("/");
      }else{
        navigate("/dashboard");
      }
    }
  }, [isloggedin]);


  return (
    <div className="App">
    
      <Routes>
            <Route path="/" element={<Login/>}></Route> 
            <Route path="*" element={<Dashboard/>}></Route> 
            <Route path="/dashboard" element={<Dashboard/>}></Route>
            <Route path="/outbound-dashboard" element={<OutBoundDashboard/>}> </Route>   
            <Route path="/master/company" element={<Company/>}> </Route>
            <Route path="/master/department" element={<Department/>}> </Route>
            <Route path="/master/employee" element={<Employee/>}> </Route>
            <Route path="/master/employee/add-employee" element={<AddEmployee/>}> </Route>
            <Route path="/master/users/user" element={<User/>}> </Route>
            <Route path="/master/users/add-user" element={<AddUser/>}> </Route>
            <Route path="/master/user/edit-user/:id" element={<Edituser/>}> </Route>
            <Route path="/master/exclude-numbers" element={<Excludenumber/>}> </Route>
            <Route path="/master/manage-call-recording" element={<Callrecording/>}> </Route>
            <Route path="/master/manage-call-recording/:type?/:user?" element={<Callrecording/>}> </Route>
            <Route path="/report/periodic-report" element={<Periodicreport/>}> </Route>
            <Route path="/report/never-attended-report" element={<Neverattended/>}> </Route>
            <Route path="/report/not-pickup-report" element={<Notpickup/>}> </Route>
            <Route path="/report/employee-reports" element={<Employeereport/>}> </Route>
            <Route path="/report/client-reports" element={<Clientreport/>}> </Route>
            <Route path="/report/app-usage" element={<AppUsage/>}> </Route>
            <Route path="/monitoring/live-monitoring" element={<LiveMonitoring/>}> </Route>
            <Route path="/master/connect-device" element={<ConnectDevice/>}> </Route>
            <Route path="/master/manage/role" element={<Role/>}> </Route>
            <Route path="/master/manage/add-role" element={<AddRole/>}> </Route>
            <Route path="/master/manage/edit-role/:id" element={<EditRole/>}> </Route>
            <Route path="/master/manage/permission" element={<Permission/>}> </Route>
            <Route path="/master/manage/menu" element={<Menu/>}> </Route>
            <Route path="/master/manage/add-menu" element={<AddMenu/>}> </Route>
            <Route path="/master/manage/add-permission" element={<AddPermission/>}> </Route>
            <Route path="/settings/backup" element={<Backup/>}> </Route>
            <Route path="/settings/break" element={<Break/>}></Route>
            <Route path="/settings/manage-apis" element={<ManageApis/>}></Route>
            <Route path="/manage-report/location" element={<LocationReport/>}> </Route>
            <Route path="/manage-report/highest-call-details" element={<Highestcall/>}> </Route> 
            <Route path="/settings/pricing-plan" element={<Pricing/>}> </Route> 
            <Route path="/settings/add-pricing-plan" element={<AddPlan/>}> </Route> 
            <Route path="/lead/lead-inbox" element={<LeadInbox/>}> </Route> 
            <Route path="/lead/lead-future" element={<LeadFuture/>}> </Route>
            <Route path="/lead/lead-negative" element={<LeadNegative/>}> </Route>
            <Route path="/lead/lead-completed" element={<LeadCompleted/>}> </Route>
            <Route path="/lead/lead-tags" element={<LeadTags/>}> </Route>
            <Route path="/lead/lead-power" element={<LeadPower/>}></Route>
            <Route path="/lead/lead-follow-up" element={<FollowUp/>}></Route>
            <Route path="/lead-template/template" element={<MailTemplate/>}></Route>
            <Route path="/lead-template/add-template" element={<AddTemplate/>}></Route>
            <Route path="/report/break-report" element={<BreakReport/>}></Route>
            <Route path="/setting/api-resource" element={<ApiResource/>}></Route>
      </Routes>
  
        
    </div>
  );
}

export default App;
