import React, { useState, useEffect } from "react";
import axios from "axios";
import { APP_URL } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import Paginationcustom from "../../validation/pagination";
import Loader from "../../loader/loader";
import TableSkeleton from "../../loader/TableSkeleton";
const Callcomponent = ({
  from_date,
  to_date,
  calltype,
  employee,
  calllog,
  setCalllog,
  loading,
  callData,
  GetCallLogHistoryData,
  callExportData,
  callLogExportHistory,
}) => {
  const [currentPage, setCurrentPage] = useState(
    callData != null ? callData.page : 1
  );
  // const [recordsPerPage] = useState(callData!=null?callData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = calllog;

  const nPages = callData != null ? callData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    GetCallLogHistoryData(parseInt(p));
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12">
          <h6>Call History ({callData && callData.total_data})</h6>
        </div>
        <div className="col-lg-12">
          <button className="btn btn-success" onClick={callLogExportHistory}>
            <i className="fa fa-download"></i> Export
          </button>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Agent name</th>
                <th>Agent code</th>
                <th>Assign No</th>
                <th>Client No</th>
                <th>Client Name</th>
                <th>Service Name</th>
                <th>Call type</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Call Duration</th>
              </tr>
            </thead>
            <tbody>
              {loading == true ? (
                <>
                  <TableSkeleton count={10} />
                </>
              ) : (
                <>
                  {currentRecords?.length > 0 ? (
                    currentRecords?.map((item, index) => (
                      <tr>
                        <td>{item.agent_name}</td>
                        <td>{item.agent_code}</td>
                        <td>{item.agent_contact_no}</td>
                        <td>{item.client_update_contact_no}</td>
                        <td>{item.client_name}</td>
                        <td>{item.service_name}</td>
                        <td>
                          {item.status == 1 ? (
                            <span className="d-flex align-items-center">
                              <i
                                className="fa fa-volume-control-phone"
                                title="incoming Call"
                                style={{
                                  color: "rgb(57, 193, 13)",
                                  fontSize: "18px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Incoming
                            </span>
                          ) : item.status == 2 ? (
                            <span className="d-flex align-items-center">
                              <i
                                className="fa fa-volume-control-phone icon-rotation"
                                title="Outgoing Call"
                                style={{
                                  color: "rgb(13, 135, 193)",
                                  fontSize: "18px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Outgoing
                            </span>
                          ) : item.status == 3 ? (
                            <span className="d-flex align-items-center">
                              <i
                                className="iconly-boldCall-Missed"
                                title="Missed"
                                style={{
                                  color: "rgb(255, 32, 32)",
                                  fontSize: "18px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Missed
                            </span>
                          ) : item.status == 4 ? (
                            <span className="d-flex align-items-center">
                              <i
                                className="fa fa-times-circle"
                                aria-hidden="true"
                                style={{
                                  color: "rgb(255, 32, 32)",
                                  fontSize: "18px",
                                  marginRight: "10px",
                                }}
                              ></i>
                              Rejected
                            </span>
                          ) : (
                            "Others"
                          )}
                        </td>
                        <td>{item.start_time}</td>
                        <td>{item.end_time}</td>
                        <td>{item.call_duration}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11">
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          No Data Found
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          <div style={{ textAlign: "right", float: "right" }}>
            <Paginationcustom
              data={nPages}
              changepage={changepage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Callcomponent;
