import axios from "axios";
import React, { useEffect, useState } from "react";
import { APP_URL } from "../../validation/basic";
import { toast } from "react-toastify";
import TableSkeleton from "../../loader/TableSkeleton";
import { CSVLink } from "react-csv";

const HourWiseAnalysis = ({ from_date, hourWiseData, loading }) => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    // const [hourWiseData, setHourWiseData] = useState([]);
    // const [loading, setLoading] = useState(false);
  
    // useEffect(() => {
    //   setToken(localStorage.getItem("token"));
    //   const currentDate = new Date().toISOString().split("T")[0];
  
    //   const handleHourWiseData = async () => {
     
    //     setLoading(true);
    //     try {
    //       const response = await axios.get(
    //         APP_URL +
    //           "periodic-report-hourly?from_date=" +
    //           from_date,
    //         { headers: { Authorization: `Bearer ${token}` } }
    //       );
    //       if (response.data.success == true) {
    //         setHourWiseData(response.data);
    //         setLoading(false);
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setLoading(false);
    //     }
    //   };
    //   handleHourWiseData();
    // }, [from_date]);
  
    // Helper function to format date
    const formatDate = (dateString) => {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const headers = [
      { label: "Hour", key: "hour" },
      { label: "Total Calls", key: "total_calls" },
      { label: "Total Incoming Calls", key: "total_incoming_calls" },
      { label: "Total Outgoing Calls", key: "total_outgoing_calls" },
      { label: "Total Missed Calls", key: "total_missed_calls" },
      { label: "Total Rejected Calls", key: "total_rejected_calls" },
      { label: "Total Durations (in sec)", key: "total_durations" },
    ];
  
    const data = [];
    hourWiseData?.data?.filter(item => item?.total_calls > 0)?.forEach((item) => {
      data.push({
        hour:item?.hour,
        total_calls: item?.total_calls ? item.total_calls : "0",
        total_incoming_calls: item?.incoming ? item?.incoming : "0",
        total_outgoing_calls: item?.outgoing ? item?.outgoing : "0",
        total_missed_calls: item?.missed ? item?.missed : "0" ,
        total_rejected_calls: item?.rejected ? item?.rejected : "0",
        total_durations: item.total_duration ,
        
      });
    });

    // Helper function to convert HH:MM:SS to total seconds
    const timeToSeconds = (timeString) => {
      const [hours, minutes, seconds] = timeString?.split(':')?.map(Number);
      return (hours * 3600) + (minutes * 60) + seconds;
    };

    // Calculate totals for each column by converting string data to numbers
    const totalCalls = hourWiseData?.data?.reduce((acc, item) => acc + Number(item.total_calls || 0), 0);
    const totalIncomingCalls = hourWiseData?.data?.reduce((acc, item) => acc + Number(item.incoming || 0), 0);
    const totalOutgoingCalls = hourWiseData?.data?.reduce((acc, item) => acc + Number(item.outgoing || 0), 0);
    const totalMissedCalls = hourWiseData?.data?.reduce((acc, item) => acc + Number(item.missed || 0), 0);
    const totalRejectedCalls = hourWiseData?.data?.reduce((acc, item) => acc + Number(item.rejected || 0), 0);
    // Convert duration strings to seconds, then sum them
    const totalDurationsInSeconds = hourWiseData?.data?.reduce((acc, item) => {
      return acc + timeToSeconds(item.total_duration || "00:00:00");
    }, 0);

// Helper function to convert total seconds back to HH:MM:SS format
    const secondsToTime = (totalSeconds) => {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Format to ensure two digits for hours, minutes, and seconds
      return [hours, minutes, seconds]
        .map(val => String(val).padStart(2, '0'))
        .join(':');
    };

    // Convert the total duration back to HH:MM:SS if needed
    const totalDurationsFormatted = secondsToTime(totalDurationsInSeconds);

  
    return (
      <>
        <div className="row mt-3">
          <div className="col-lg-12 ">
          <h6>Hour Wise Analysis <span className="text-primary">({formatDate(from_date)})</span></h6>
            <CSVLink
            data={data}
            headers={headers}
            filename={"hour-wise-report.csv"}
            className="btn btn-success"
            target="_blank"
          >
            <i className="fa fa-download"></i> Export
          </CSVLink>
           
          </div>
          <div className="col-lg-12 mt-3">
            {/* <button
              className="btn btn-success"
              onClick={neverAttendedReportExport}
            >
              <i className="fa fa-download"></i> Export
            </button> */}
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th rowSpan="2" style={{ width: "200px" }}>
                    Hourly Time Slot
                  </th>
                  <th rowSpan="2">Total Calls</th>
                  <th rowSpan="2">Total Incoming Calls</th>
                  <th rowSpan="2">Total Outgoing Calls</th>
                  <th rowSpan="2">Total Missed Calls</th>
                  <th rowSpan="2">Total Rejected Calls</th>
                  <th rowSpan="2">Total Durations (in sec)</th>
                </tr>
              </thead>
              <tbody>
                {loading == true ? (
                  <>
                    <TableSkeleton count={7} />
                  </>
                ) : (
                  <>
                    {hourWiseData?.data?.length > 0 ? (
                      hourWiseData?.data?.filter(item => item?.total_calls > 0)?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.hour}</td>
                          <td>{item?.total_calls ? item.total_calls : "0"}</td>
                          <td>
                            {item?.incoming ? item?.incoming : "0"}
                          </td>
                          <td>
                            {item?.outgoing ? item?.outgoing : "0"}
                          </td>
                          <td>{item?.missed ? item?.missed : "0"}</td>
                          <td>{item?.rejected ? item?.rejected : "0"}</td>
                          <td>{item?.total_duration}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="11">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            No Data Found
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
              {!loading && hourWiseData?.data?.length > 0 && (
              <tfoot>
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{totalCalls}</strong></td>
                  <td><strong>{totalIncomingCalls}</strong></td>
                  <td><strong>{totalOutgoingCalls}</strong></td>
                  <td><strong>{totalMissedCalls}</strong></td>
                  <td><strong>{totalRejectedCalls}</strong></td>
                  <td><strong>{totalDurationsFormatted}</strong></td>
                </tr>
              </tfoot>
            )}
            </table>
            {/* <div style={{ textAlign: "right", float: "right" }}>
              <Paginationcustom
                data={nPages}
                changepage={changepage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div> */}
          </div>
        </div>
      </>
    );
}

export default HourWiseAnalysis;