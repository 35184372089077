import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../layout/topbar";
import Footer from "../../layout/footer";
import axios from "axios";
import { APP_URL, getdates } from "../../validation/basic";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import Paginationcustom from "../../validation/pagination";
import Select from "react-select";
import TableSkeleton from "../../loader/TableSkeleton";
import { useSelector } from "react-redux";
import { selectUser } from "../../../app/userslice";

const LocationReport = () => {
  const [fromdate, setFromdate] = useState(getdates(0));
  const [todate, setTodate] = useState(getdates(0));
  const [employee, setEmployee] = useState("");
  const [locationlist, setLocationlist] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [employeelist, setEmployeelist] = useState([]);
  const [selectedemp, setSelectedemp] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const user = useSelector(selectUser);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    loadusers();
    loadlocationreport(1);
  }, []);

  /*--pagination start here---*/

  const [currentPage, setCurrentPage] = useState(
    locationData != null ? locationData.page : 1
  );
  // const [recordsPerPage] = useState(locationData!=null?locationData.limit:null);
  /*--pagination start here---*/

  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = locationlist;

  const nPages = locationData != null ? locationData.total_page_count : null;
  //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const changepage = (e, p) => {
    setCurrentPage(p);
    loadlocationreport(parseInt(p));
  };
  /*--pagination start end here---*/

  const loadusers = async () => {
    try {
      const users = await axios.get(APP_URL + "users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let users_list_data = JSON.parse(JSON.stringify(users.data));
      setEmployeelist(users_list_data.data.user_list);
    } catch (e) {
      return null;
    }
  };

  const handlefromdatechange = (e) => {
    setFromdate(e.target.value);
  };

  const handletodatechange = (e) => {
    setTodate(e.target.value);
  };

  const handleemployeechange = (e) => {
    setSelectedemp(e);
    setEmployee(e.value);
  };

  const loadlocationreport = async (page) => {
    try {
      setLoading(true);
      const location_data = await axios.get(
        APP_URL +
          "locations?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&master_user_id=" +
          `${employee ? "" : localStorage.getItem("isloggedin")}` + 
          "&user_id=" +
          `${employee}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let location_data_list = JSON.parse(JSON.stringify(location_data.data));
      if (location_data_list.status == 1) {
        setLocationlist(location_data_list.data.locations);
        setLocationData(location_data_list.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  const loadlocationReportFilter = async (page) => {
    try {
      setLoading(true);
      const location_data = await axios.get(
        APP_URL +
          "locations?from_date=" +
          `${fromdate}` +
          "&to_date=" +
          `${todate}` +
          "&user_id=" +
          `${employee}` +
          "&page=" +
          `${page}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let location_data_list = JSON.parse(JSON.stringify(location_data.data));
      if (location_data_list.status == 1) {
        setLocationlist(location_data_list.data.locations);
        setLocationData(location_data_list.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  const headers = [
    { label: "Agent name", key: "agent_name" },
    { label: "Agent code", key: "agent_code" },
    { label: "Location", key: "location" },
    { label: "Date", key: "date" },
  ];

  const data = [];
  locationlist?.forEach((item) => {
    data.push({
      agent_name: item.user != null ? item.user.name : null,
      agent_code: item.user != null ? item.user.user_unique_code : null,
      location: item.full_address,
      date: item.date,
    });
  });

  const employeedata = [{ value: "", label: "All" }];
  employeelist?.forEach((item) => {
    employeedata.push({
      value: item.id,
      label: item.name + "-" + item.user_unique_code,
    });
  });

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>
                              Location Details (
                              {locationData && locationData?.total_data})
                            </h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <table className="table table table-borderless mb-0">
                                    <thead>
                                      <tr>
                                        <th>From date</th>
                                        <th>To date</th>
                                        <th>Select Employee</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handlefromdatechange(e)
                                            }
                                            value={fromdate}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            onChange={(e) =>
                                              handletodatechange(e)
                                            }
                                            value={todate}
                                          />
                                        </td>
                                        <td style={{ width: "250px" }}>
                                          <Select
                                            value={selectedemp}
                                            onChange={(e) =>
                                              handleemployeechange(e)
                                            }
                                            options={employeedata}
                                          />
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-success"
                                            onClick={() =>
                                              loadlocationreport(1)
                                            }
                                          >
                                            Apply
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-sm-12">
                                  <h6>Reports</h6>
                                </div>
                                <CSVLink
                                  data={data}
                                  headers={headers}
                                  filename={"location-report.csv"}
                                  className="btn btn-success"
                                  target="_blank"
                                >
                                  <i className="fa fa-download"></i> Export
                                </CSVLink>
                                <div className="table-responsive pb-5 mt-3">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Agent Name</th>
                                        <th>Agent Code</th>
                                        <th>Location</th>
                                        <th>Location Info</th>
                                        <th>Date</th>
                                        <th>Map</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading == true ? (
                                        <>
                                          <TableSkeleton count={5} />
                                        </>
                                      ) : (
                                        <>
                                          {currentRecords?.length > 0 ? (
                                            currentRecords?.map(
                                              (item, index) => (
                                                <>
                                                <tr>
                                                  <td>{item?.user?.name}</td>
                                                  <td>
                                                    {item?.user?.user_unique_code}
                                                  </td>
                                                  <td>{item?.full_address}</td>
                                                  <td>
                                                    <strong>
                                                      Lattitude :{" "}
                                                    </strong>
                                                    {item?.lat}
                                                    <br />
                                                    <strong>
                                                      Longitude :{" "}
                                                    </strong>
                                                    {item.lng}
                                                    <br />
                                                  </td>
                                                  <td>{item?.date}</td>
                                                  <td>
                                                    <a
                                                      // href={`https://www.latlong.net/c/?lat=${item?.lat}&long=${item?.lng}`}
                                                      // href={`https://gps-coordinates.org/my-location.php?lat=${item?.lat}&lng=${item?.lng}`}
                                                      href={item?.address_url}
                                                      target="_blank"
                                                      title="Navigate to map"
                                                      rel="noreferrer"
                                                    >
                                                      <i
                                                        className="fa fa-map-marker"
                                                        style={{fontSize: "35px"}}
                                                      ></i>
                                                    </a>
                                                  </td>
                                                  </tr>
                                                </>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan="11">
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "18px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  No Data Found
                                                </div>
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  style={{ textAlign: "right", float: "right" }}
                                >
                                  <Paginationcustom
                                    data={nPages}
                                    changepage={changepage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationReport;
